@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
  font-family: "Avenir-Black";
  src: url("./assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: "Avenir-Book";
  src: url("./assets/fonts/AvenirLTStd-Book.otf");
}

@font-face {
  font-family: "Avenir-Roman";
  src: url("./assets/fonts/AvenirLTStd-Roman.otf");
}

body {
  background: #121212;
  margin: 0;
  font-family: "Avenir-Book";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
