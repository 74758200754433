abbr:where([title]) {
  @apply !no-underline;
}

.timeslotmarker {
  background-image: url("./assets/svg/timeslotmarker.svg");
}

.react-calendar__tile--now {
  @apply !bg-transparent;
}

.react-calendar__navigation {
  @apply !flex !justify-center !mb-[44px] !text-white;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  @apply !hidden;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  @apply !text-[14px] !font-medium;
}

.react-calendar__tile {
  @apply hover:!bg-[#121212] hover:!text-white !my-[12px] !text-[14px] !font-medium disabled:!bg-[#202020] disabled:!bg-opacity-50 disabled:!cursor-not-allowed disabled:hover:!text-[#B8B8B8];
}
.react-calendar__navigation button:disabled {
  @apply !cursor-not-allowed !bg-transparent;
}

.react-calendar__navigation__label {
  @apply hover:!bg-[transparent] focus:!bg-[transparent];
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  @apply !text-[20px] hover:!bg-transparent focus:!bg-transparent;
}

.react-calendar__month-view__days__day--weekend {
  @apply !text-[#B8B8B8];
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth {
  @apply !text-[#757575];
}
.react-calendar__tile--active {
  @apply hover:!bg-[#DF5B04] !bg-[#DF5B04] !text-white;
}

.react-calendar__month-view__weekdays__weekday {
  @apply !text-[13px] !font-normal;
}

.react-calendar__month-view__weekdays {
  @apply !mb-[20px] !capitalize;
}
.completed::after {
  @apply !z-10 !bg-[#DF5B04] !bg-opacity-50;
}

.incomplete::after {
  @apply !z-10 !bg-[#b8b8b8] !bg-opacity-50;
}

.carousel-track {
  @apply !h-[320px] !overflow-x-scroll !overflow-y-hidden;
}

.projecttitle {
  display: -webkit-box;
  max-width: 400px;
  height: calc(16 * 1.375 * 2);
  font-size: 18px;
  line-height: 1.375;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

/* .slider-thumb::-webkit-slider-thumb {
  @apply appearance-none w-3 h-3 rounded-full bg-login-button-bg cursor-pointer;
}

.slider-thumb::-webkit-slider-thumb:hover {
  @apply bg-blue-700;
} */
